import {ActionEvent, Controller} from '@hotwired/stimulus';
import {Typed} from "app/stimulus/typed-stimulus";

export default class extends Typed(
    Controller<HTMLElement>, {
        targets: {
            entry5Table: HTMLTableElement,
            entry6Table: HTMLTableElement,
            entry7ExtraInfo0: HTMLInputElement,
            entry7ExtraInfo1: HTMLInputElement,
            entry7ExtraInfo2: HTMLInputElement,
            containerEntry7ExtraInfo0: HTMLDivElement,
            containerEntry7ExtraInfo1: HTMLDivElement,
            containerEntry7ExtraInfo2: HTMLDivElement,
        }
    }
)
{
    public answerSelected(event: ActionEvent): void {
        const questionNumber = event.params.questionNumber;
        const tag = event.params.tag;
        if (questionNumber === 4) {
            this.entry5TableTarget.style.display = tag === 'a' ? 'none' : 'table';
            this.entry6TableTarget.style.display = tag === 'a' ? 'none' : 'table';
        } else if (questionNumber === 7) {
            this.entry7ExtraInfo0Target.disabled = tag !== 'b';
            this.containerEntry7ExtraInfo0Target.style.display = tag === 'b' ? 'block' : 'none';
            this.entry7ExtraInfo1Target.disabled = tag !== 'c';
            this.containerEntry7ExtraInfo1Target.style.display = tag === 'c' ? 'block' : 'none';
            this.entry7ExtraInfo2Target.disabled = tag !== 'd';
            this.containerEntry7ExtraInfo2Target.style.display = tag === 'd' ? 'block' : 'none';
        }
    }
}
