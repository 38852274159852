import '@fortawesome/fontawesome-free/css/all.css';
import 'app/extension/_extensions';
import 'app/styles/app.scss';
import {componentsLoader} from 'app/service/components-loader';
import {logger} from 'app/service/logger';
import {flashModalMessenger} from 'app/service/flash-modal-messenger';
import { startStimulusApp } from '@symfony/stimulus-bridge';
import {Controller} from "@hotwired/stimulus";

// Registers Stimulus controllers from controllers.json and in the controllers/ directory
export const app = startStimulusApp(require.context(
    '@symfony/stimulus-bridge/lazy-controller-loader!./controllers',
    true,
    /\.[jt]sx?$/
));

export const getController = <T extends Controller>(element: HTMLElement, identifier: string): T | null => {
    return app.getControllerForElementAndIdentifier(element, identifier) as T | null;
}

export const getControllerAsync = async <T extends Controller>(
    element: HTMLElement,
    identifier: string,
    timeout: number = 5000,
    poll: number = 50,
): Promise<T | null> => {
    const startTime = Date.now();
    let attempts = 0;
    let maxAttempts = 10;
    return new Promise((resolve) => {
        const checkController = () => {
            attempts++;
            const controller = app.getControllerForElementAndIdentifier(element, identifier) as T | null;
            if (controller !== null) {
                resolve(controller);
            } else if (Date.now() - startTime >= timeout) {
                resolve(null);
            } else if (attempts <= maxAttempts) {
                setTimeout(checkController);
            } else {
                setTimeout(checkController, poll);
            }
        };
        checkController();
    });
};

// register any custom, 3rd party controllers here
// app.register('some_controller_name', SomeImportedController);

componentsLoader.load();
flashModalMessenger.handle();

const url = new URL(window.location.href);
if (url.searchParams.has('close-window')) {
    try {
        window.close();
    } catch (e) {
        logger.log(e);
    }
}

