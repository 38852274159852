import {Controller} from '@hotwired/stimulus';
import {NotificationReadEventDetail} from 'app/controllers/notifications-reader-list-item-controller';
import {Typed} from "app/stimulus/typed-stimulus";

export default class extends Typed(
    Controller<HTMLElement>, {
        values: {
            id: Number,
        },
        classes: [
            'unread',
        ] as const,
    }
)
{
    public handleNotificationRead(event: CustomEvent<NotificationReadEventDetail>): void {
        if (event.detail.id !== this.idValue) {
            return;
        }
        this.element.classList.remove(this.unreadClass);
    }
}
