import {Controller} from '@hotwired/stimulus';
import {Typed} from "app/stimulus/typed-stimulus";

export default class extends Typed(
    Controller<HTMLElement>, {
        targets: {
            submenu: HTMLElement,
            unreadCount: HTMLElement,
        },
        values: {
            unreadCount: {type: Number, default: 0},
        },
        classes: [
            'unread',
        ] as const,
    }
)
{
    private isOpened: boolean;

    public connect() {
        const styles = getComputedStyle(this.submenuTarget);
        this.isOpened = styles.display !== 'none';
    }

    public handleGlobalClick(event: MouseEvent): void {
        if (!this.isOpened) {
            return;
        }
        const eventTarget = event.target as HTMLElement;
        if (this.element === eventTarget || this.element.contains(eventTarget)) {
            return;
        }
        if (this.submenuTarget === eventTarget || this.submenuTarget.contains(eventTarget)) {
            return;
        }
        this.closeSubmenu();
    }

    public handleNotificationRead(): void {
        if (this.unreadCountValue === 0) {
            return;
        }
        this.unreadCountValue--;
        this.unreadCountTarget.innerHTML = this.unreadCountValue.toString();
        if (this.unreadCountValue === 0) {
            this.unreadCountTarget.remove();
            this.element.classList.remove(this.unreadClass);
        }
    }

    public toggleSubmenu(): void {
        this.isOpened ? this.closeSubmenu() : this.openSubmenu();
    }

    private openSubmenu(): void {
        if (this.isOpened) {
            return;
        }
        this.isOpened = true;
        $(this.submenuTarget).slideDown();
    }

    private closeSubmenu(): void {
        if (!this.isOpened) {
            return;
        }
        this.isOpened = false;
        $(this.submenuTarget).slideUp();
    }
}
