import {logger} from 'app/service/logger';
import {messenger} from 'app/utils/messenger';
import {Controller} from '@hotwired/stimulus';
import {Typed} from "app/stimulus/typed-stimulus";

export default class extends Typed(
    Controller<HTMLElement>, {
        targets: {
            title: HTMLElement,
            annotation: HTMLElement,
            date: HTMLElement,
            content: HTMLElement,
        },
        values: {
            setAsReadUrl: String,
            isUnread: Boolean,
            id: Number,
        },
        classes: [
            'unread',
        ] as const,
    }
)
{
    public async click(): Promise<void> {
        this.dispatch('click', {
            detail: {
                id: this.idValue,
                title: this.titleTarget.innerHTML,
                annotation: this.annotationTarget.innerHTML,
                date: this.dateTarget.innerHTML,
                description: this.contentTarget.innerHTML,
            },
            bubbles: true,
            cancelable: true,
        });
        if (!this.isUnreadValue) {
            return;
        }
        try {
            const response = await fetch(this.setAsReadUrlValue, {method: 'PUT'});
            const json: Response = await response.json();
            if (json.status !== 'success') {
                throw new Error(json.message ?? APP_CONFIG.messages.common.error);
            }
            this.element.classList.remove(this.unreadClass);
            this.isUnreadValue = false;
            this.dispatch('notification:read', {
                prefix: '',
                detail: {
                    id: this.idValue,
                },
                bubbles: true,
                cancelable: true,
            });
        } catch (error) {
            logger.log(error);
            messenger.error(APP_CONFIG.messages.common.error);
        }
    }
}

type Response = {
    status: string;
    message?: string;
}

export type ClickEventDetail = {
    id: number;
    title: string;
    annotation: string;
    date: string;
    description: string;
}

export type NotificationReadEventDetail = {
    id: number;
};
